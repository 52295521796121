import React, { useState } from "react";
import request from "./shared/request";

export const AppContext = React.createContext();

function AppProvider(props) {
  const [users, setUsers] = useState([]);
  const [uuidList, setUuidList] = useState([]);
  const [zipcodes, setZipCodes] = useState([]);
  const [products, setProducts] = useState([]);
  const [orders, setOrders] = useState([]);

  const getAllUsers = async () => {
    const response = await request("/admin/registeredUserList");
    if (response && response.data) {
      setUsers(response.data);
    }
  };

  const getAllUuid = async (pageno, filters) => {
    const response = await request(
      pageno
        ? filters
          ? `/admin/getUserUuidList?page=${pageno}${filters}`
          : `/admin/getUserUuidList?page=${pageno}`
        : `/admin/getUserUuidList?${filters}`
    );
    if (response && response.data) {
      setUuidList(response.data);
    }
  };

  const getAllZipcodes = async (pageno) => {
    const response = await request(
      pageno
        ? `/admin/getZipCodeDetails?page=${pageno}`
        : "/admin/getZipCodeDetails"
    );
    if (response && response.data) {
      setZipCodes(response.data);
    }
  };

  const getAllProducts = async (pageno) => {
    const response = await request(
      pageno
        ? `/admin/getProductTypeList?page=${pageno}`
        : "/admin/getProductTypeList"
    );
    if (response && response.data) {
      setProducts(response.data);
    }
  };

  const getAllOrders = async (pageno) => {
    const response = await request(
      pageno ? `/admin/viewAllOrders?page=${pageno}` : "/admin/viewAllOrders"
    );
    if (response && response.data) {
      setOrders(response.data);
    }
  };

  return (
    <AppContext.Provider
      value={{
        users,
        getAllUsers,
        uuidList,
        getAllUuid,
        zipcodes,
        getAllZipcodes,
        products,
        getAllProducts,
        orders,
        getAllOrders,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
}

const AppConsumer = AppContext.Consumer;

export { AppProvider, AppConsumer };
