import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import Navbar from "./shared/Navbar";
import Sidebar from "./shared/Sidebar";
import "./reset.css";
import "../assets/css/font-awesome.css";
import "../assets/css/styles.css";

function App({ location }) {
  const history = useHistory();
  const [isFullPageLayout, setisFullPageLayout] = useState(false);

  const onRouteChanged = () => {
    const body = document.querySelector("body");
    if (location.pathname === "/layout/RtlLayout") {
      body.classList.add("rtl");
    } else {
      body.classList.remove("rtl");
    }
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = [
      "/login",
      "/register",
      "/forgot",
      "/404",
      "/500",
      "/chipinfo",
      "/chipinfo/:shortkey",
    ];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (
        location.pathname === fullPageLayoutRoutes[i] ||
        location.pathname.indexOf("/chipinfo") > -1
      ) {
        setisFullPageLayout(true);
        document
          .querySelector(".page-body-wrapper")
          .classList.add("full-page-wrapper");
        break;
      } else {
        setisFullPageLayout(false);
        document
          .querySelector(".page-body-wrapper")
          .classList.remove("full-page-wrapper");
      }
    }
  };

  useEffect(() => {
    if (
      !window.localStorage.getItem("token") &&
      location.pathname !== "/chipinfo" &&
      location.pathname.indexOf("/chipinfo") === -1
    ) {
      history.push("/login");
    } else {
      onRouteChanged();
    }
  }, []);

  useEffect(() => {
    onRouteChanged();
  }, [location]);

  let navbarComponent = !isFullPageLayout ? <Navbar /> : "";
  let sidebarComponent = !isFullPageLayout ? <Sidebar /> : "";
  return (
    <div className="container-scroller">
      {navbarComponent}
      <div className="container-fluid page-body-wrapper">
        {sidebarComponent}
        <div
          className="main-panel"
          style={!isFullPageLayout ? { marginLeft: "210px" } : {}}
        >
          <div className="content-wrapper">
            <AppRoutes />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(App);
