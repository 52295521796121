const hostname = window && window.location && window.location.hostname;
const env = hostname === "localhost" ? "development" : "production";

const production = {
  ENV: "production",
  OB_ROLE: "admin",
  HOST: "https://api.dotsco.co",
  S3: "https://dotsco-resources.s3.me-south-1.amazonaws.com/",
};

const development = {
  ENV: "staging",
  OB_ROLE: "admin",
  HOST: "https://api.dotsco.co",
  S3: "https://dotsco-resources.s3.me-south-1.amazonaws.com/",
};

const config = {
  development,
  production,
};

export default config[env];

export const API_ROOT = `${config[env].HOST}`;

export const IMAGE_DOMAIN = `${config[env].S3}`;

export const getAPIURL = (resourceURL) => `${config[env].HOST}/${resourceURL}`;
