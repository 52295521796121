import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { SidebarRoutes } from "../shared/constant";
import Dash from "../../assets/images/dash.svg";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import Log from "../../assets/images/log-out.svg";

class Sidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });
  }

  logout(){
    localStorage.clear();
  }
  

  render() {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="text-center sidebar-brand-wrapper d-flex justify-content-center align-items-center">
        {/*  <img src={Logo} alt="logo" width="60px" />*/} 
        </div>
        <ul className="nav">
          {SidebarRoutes.map((p,i) => (
            <OverlayTrigger
              key={i}
              placement="right"
              overlay={<p></p>}
            >
              <li
                key={i}
                className={
                  this.isPathActive(p.path) ? "nav-item active side-active" : "nav-item side-inactive"
                }
              >
                <Link className="nav-link" to={p.path}>
                  <img src={`${p.icon}`} alt="..."/>
                  <p className="side-nav-name">{p.displayName}</p>
                  {/*   <i className={`fa ${p.icon} menu-icon`}></i> <p className="side-nav-name">{p.displayName}</p>
                   <span className="menu-title">{p.displayName}</span> */}
                </Link>
              </li>
            </OverlayTrigger>
          ))}

          <OverlayTrigger
              placement="right"
              overlay={<p></p>}
            >
              <li className="nav-item side-inactive" onClick={()=>this.logout()}>
                <Link className="nav-link" to="/login">
                  <img src={Log} alt="..."/>
                  <p className="side-nav-name">Logout</p>
                </Link>
              </li>
            </OverlayTrigger>
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
  }
}

export default withRouter(Sidebar);
