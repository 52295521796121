import React, { Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import AuthenticatedRoute from "./AuthenticatedRoute";

import Spinner from "../app/shared/Spinner";

const Dashboard = lazy(() => import("./pages/dashboard"));
const Login = lazy(() => import("./login"));
const ForgotPassword = lazy(() => import("./login/forgot-password"));
const Mdi = lazy(() => import("./icons/Mdi"));
const SocialMedia = lazy(() => import("./pages/socialMedia"));
const SMCategory = lazy(() => import("./smProfile/components/category"));
const Error404 = lazy(() => import("./error-pages/Error404"));
const Error500 = lazy(() => import("./error-pages/Error500"));
const Users = lazy(() => import("./pages/users"));
const Uuid = lazy(() => import("./pages/uuid"));
const Estore = lazy(() => import("./pages/settings"));
const Products = lazy(() => import("./pages/products"));
const ProductInfo = lazy(() => import("./pages/products/product/ProductInfo"));
const Videos = lazy(() => import("./tutorialVideos"));
const Country = lazy(() => import("./pages/settings/country"));
const State = lazy(() => import("./pages/settings/state"));
const City = lazy(() => import("./pages/settings/city"));
const Zipcode = lazy(() => import("./pages/settings/zipcode"));
const Orders = lazy(() => import("./pages/orders"));
const MyProfile = lazy(() => import("./myprofile"));
const Enquiry = lazy(() => import("./pages/enquiry"));

function AppRoutes() {
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <AuthenticatedRoute exact path="/" component={Login} />
        <AuthenticatedRoute exact path="/smprofile" component={SocialMedia} />
        <AuthenticatedRoute exact path="/smcategory" component={SMCategory} />
        <AuthenticatedRoute path="/users" component={Users} />
        <AuthenticatedRoute path="/uuid" component={Uuid} />
        <AuthenticatedRoute path="/myprofile" component={MyProfile} />
        <AuthenticatedRoute exact path="/estore" component={Estore} />
        <AuthenticatedRoute path="/videos" component={Videos} />
        <AuthenticatedRoute path="/estore/zipcode" component={Zipcode} />
        <AuthenticatedRoute path="/estore/country" component={Country} />
        <AuthenticatedRoute path="/estore/state" component={State} />
        <AuthenticatedRoute path="/estore/city" component={City} />
        <AuthenticatedRoute path="/orders" component={Orders} />
        <AuthenticatedRoute path="/products" component={Products} />
        <AuthenticatedRoute path="/product/:id" component={ProductInfo} />
        <AuthenticatedRoute path="/enquiry" component={Enquiry} />
        <Route path="/icons/mdi" component={Mdi} />
        <Route path="/login" component={Login} />
        <Route path="/forgot" component={ForgotPassword} />
        <Route path="/" component={Dashboard} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/404" component={Error404} />
        <Route path="/500" component={Error500} />
        <Redirect
          to={{
            pathname: "/404",
          }}
        />
      </Switch>
    </Suspense>
  );
}

export default AppRoutes;
