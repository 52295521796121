import Dash from "../../assets/images/dash.svg";
import Staff from "../../assets/images/staff.svg";
import Soci from "../../assets/images/soci.svg";
import Uiud from "../../assets/images/uiud.svg";
import Setting from "../../assets/images/setting.svg";
import Pro from "../../assets/images/pro-ss.svg";
import Shop from "../../assets/images/shopp.svg";
import Tut from "../../assets/images/tut.svg";
import Sto from "../../assets/images/sto.svg";


export const ROUTES = [
  // {
  //   path: "dashboard",
  //   pageName: "Dashboard",
  // },
  {
    path: "users",
    pageName: "Staff & Users",
  },
  {
    path: "smprofile",
    pageName: "Social Media Profile",
  },
  {
    path: "smcategory",
    pageName: "Social Media Category",
  },
  {
    path: "staff",
    pageName: "Staff",
  },
  {
    path: "uuid",
    pageName: "UUID",
  },
  {
    path: "myprofile",
    pageName: "My Profile",
  },
  {
    path: "estore/zipcode",
    pageName: "ZipCode",
  },
  {
    path: "estore/country",
    pageName: "Country",
  },
  { path: "estore/state", pageName: "State" },
  { path: "estore/city", pageName: "City" },
  { path: "estore/products", pageName: "Products" },
  { path: "estore/orders", pageName: "Orders" },
  {
    path: "estore",
    pageName: "Estore",
  },
  {
    path: "videos",
    pageName: "Tutorial Videos",
  },
  {
    path: "common",
    pageName: "Common",
  },
];

export const Coupontype = [
  {
    value: "pecentage",
    label: "Percentage(%)",
  },
  {
    value: "fixed",
    label: "Fixed",
  },
];

export const discountType = [
  {
    value: 2,
    label: "Percentage(%)",
  },
  {
    value: 1,
    label: "Flat",
  },
];

export const SidebarRoutes = [
  {
    id: 1,
    path: "/dashboard",
    displayName: "Dashboard",
    icon: Dash,
  },
  {
    id: 2,
    path: "/users",
    displayName: "Users & Staff",
    icon: Staff,
  },
  {
    id: 3,
    path: "/smprofile",
    displayName: "Social Media",
    icon: Soci,
  },
  {
    id: 6,
    path: "/uuid",
    displayName: "DOTS UUID",
    icon: Uiud,
  },
  {
    id: 7,
    path: "/orders",
    displayName: "Orders",
    icon: Shop,
  },
  {
    id: 7,
    path: "/products",
    displayName: "Products",
    icon: Pro,
  },
  {
    id: 7,
    path: "/estore",
    displayName: "Setting",
    icon: Sto,
  },
  {
    id: 9,
    path: "/videos",
    displayName: "Tutorial Videos",
    icon: Tut,
  },
  {
    id: 10,
    path: "/enquiry",
    displayName: "Enquiry Details",
    icon: Setting,

  },
  // {
  //   id: 11,
  //   path: "/help-center",
  //   displayName: "Help Center",
  //   icon: Help,
  //  }
];

export const estore = [
  {
    id: 1,
    path: "/estore/zipcode",
    displayName: "ZipCode",
    icon: "fa-location-arrow",
  },
  {
    id: 2,
    path: "/estore/country",
    displayName: "Country",
    icon: "fa-address-book",
  },
  {
    id: 3,
    path: "/estore/state",
    displayName: "State",
    icon: "fa-address-book",
  },
  {
    id: 5,
    path: "/estore/products",
    displayName: "Products",
    icon: "fa-cube",
  },
  {
    id: 6,
    path: "/estore/orders",
    displayName: "Orders",
    icon: "fa-first-order",
  },
];

export const ChipTypes = [
  {
    value: "213",
    label: "213",
  },
  {
    value: "216",
    label: "216",
  },
];